import React from 'react';
import {
  Box,
  Heading,
  useColorModeValue,
  Text,
  Button,
} from '@chakra-ui/react';
import { useInfiniteFetch } from '../utils/api';

function News() {
  const colorModeValue = useColorModeValue('white', 'gray.800');
  const { isFetching, data, hasNextPage, fetchNextPage } = useInfiniteFetch(
    '../resource/app-article',
    'get',
    {
      rowsPerPage: 30,
    }
  );

  return (
    <div>
      <Heading as="h2" size="lg" marginY={`2rem`}>
        News
      </Heading>
      {data && (
        <React.Fragment>
          {data.pages.map(({ processedData = [] }) => {
            return processedData.map(article => {
              return (
                <Box
                  key={article.id}
                  p="8"
                  bg={colorModeValue}
                  rounded={'lg'}
                  boxShadow={'lg'}
                  marginBottom={6}
                >
                  <Heading marginBottom="6">{article.title}</Heading>
                  <Text
                    dangerouslySetInnerHTML={{ __html: article.content }}
                  ></Text>
                </Box>
              );
            });
          })}
        </React.Fragment>
      )}
      {(hasNextPage || isFetching) && (
        <Button
          display={'block'}
          width={'100%'}
          onClick={() => fetchNextPage()}
          disabled={isFetching}
          isLoading={isFetching}
        >
          Load More
        </Button>
      )}
    </div>
  );
}

export default News;
