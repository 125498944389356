import React, { useState } from 'react';
import { useSignIn } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Stack,
  Button,
  Text,
  Link,
  useColorModeValue,
} from '@chakra-ui/react';
import { useRequest } from './utils/api';

function LoginPassword({ defaultLoginWith, onChangeMode }) {
  const [loginWith, setLoginWith] = useState(defaultLoginWith);
  const [loginError, setLoginError] = useState(null);
  const signIn = useSignIn();
  const navigate = useNavigate();
  const { request: loginRequest } = useRequest('login', 'post');
  const colorModeValue = useColorModeValue('white', 'gray.700');

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
  } = useForm();

  const onLogin = async values => {
    setLoginError(null);
    const { data, error } = await loginRequest({
      ...values,
      method: loginWith === 'mobile' ? 'contact' : 'email',
    });
    if (error) {
      setLoginError(error.message);
      return;
    }
    if (
      signIn({
        token: data.access_token,
        expiresIn: data.expires_in,
        tokenType: data.token_type,
        authState: data.data,
      })
    ) {
      navigate('/home');
    } else {
      setLoginError('Error logging in.');
    }
  };

  return (
    <Box rounded={'lg'} bg={colorModeValue} boxShadow={'lg'} p={8}>
      <form onSubmit={handleSubmit(onLogin)}>
        {loginError && (
          <Text color="tomato" align={'center'} className="tw-mb-2">
            {loginError}
          </Text>
        )}
        <Stack spacing={4}>
          <FormControl id="username" isInvalid={errors.username}>
            <FormLabel htmlFor="username">
              <Link
                float="right"
                fontSize={'xs'}
                marginTop={'.25rem'}
                textColor="blue.400"
                onClick={() => {
                  setLoginWith(loginWith === 'email' ? 'mobile' : 'email');
                  reset();
                }}
              >
                {loginWith === 'email' ? 'Use Mobile' : 'Use Email'}
              </Link>
              {loginWith === 'email' ? 'Email' : 'Mobile Number'}
            </FormLabel>
            {loginWith === 'email' ? (
              <Input
                type="email"
                id="username"
                readOnly={isSubmitting}
                {...register('username', {
                  required: 'This is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                })}
              />
            ) : (
              <Input
                type="text"
                id="username"
                readOnly={isSubmitting}
                placeholder="01xxxxxxxx"
                {...register('username', {
                  required: 'This is required',
                  minLength: {
                    value: 4,
                    message: 'Minimum length should be 9',
                  },
                })}
              />
            )}
            {!!errors.username && (
              <FormErrorMessage>{errors.username.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl id="password" isInvalid={errors.password}>
            <FormLabel htmlFor="password">Password</FormLabel>
            <Input
              type="password"
              id="password"
              readOnly={isSubmitting}
              {...register('password', {
                required: 'This is required',
              })}
            />
            {!!errors.password && (
              <FormErrorMessage>{errors.password.message}</FormErrorMessage>
            )}
          </FormControl>

          <Stack spacing={4}>
            <Button
              isLoading={isSubmitting}
              type="submit"
              bg={'blue.400'}
              color={'white'}
              _hover={{ bg: 'blue.500' }}
            >
              Login
            </Button>
            <Link
              fontSize={'xs'}
              textAlign={'center'}
              textColor="blue.400"
              onClick={() => onChangeMode(loginWith)}
            >
              Forgot Password / First Time Login
            </Link>
          </Stack>
        </Stack>
      </form>
    </Box>
  );
}

export default LoginPassword;
