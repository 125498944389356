import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Flex,
  Icon,
  useColorModeValue,
  CloseButton,
} from '@chakra-ui/react';
import { FiHome, FiShoppingBag } from 'react-icons/fi';
import { BiCommentEdit, BiWallet, BiAlarm } from 'react-icons/bi';
import { BsGift } from 'react-icons/bs';
import { RiCoupon3Line } from 'react-icons/ri';
import VerticalSelector from './commons/VerticalSelector';

const LinkItems = [
  { name: 'Home', path: '/home', icon: FiHome },
  { name: 'Transactions', path: '/transactions', icon: FiShoppingBag },
  { name: 'Point Statement', path: '/points', icon: BsGift },
  { name: 'Prepaid Statement', path: '/prepaid', icon: BiWallet },
  { name: 'Hours Statement', path: '/hours', icon: BiAlarm },
  { name: 'Vouchers', path: '/vouchers', icon: RiCoupon3Line },
  { name: 'Feedback', path: '/feedback', icon: BiCommentEdit },
];

const NavItem = ({ icon, path, onClick, children, ...rest }) => {
  return (
    <RouterLink
      to={path}
      onClick={onClick}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'cyan.400',
          color: 'white',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </RouterLink>
  );
};

export default function SidebarContent({ onClose, ...rest }) {
  return (
    <Box
      transition="width 3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Box display={{ base: 'none', md: 'block' }}>
          <RouterLink to={'/'}>
            <img
              src="./logo_full.png"
              alt="SS Wellness"
              style={{ height: '60px' }}
            />
          </RouterLink>
        </Box>
        <Box display={{ base: 'block', md: 'none' }}>
          <VerticalSelector />
        </Box>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map(link => (
        <NavItem
          key={link.name}
          icon={link.icon}
          path={link.path}
          onClick={onClose}
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
}
