import React, { useState, useContext } from 'react';
import {
  Heading,
  Box,
  Text,
  Button,
  Select,
  VStack,
  RadioGroup,
  Radio,
  HStack,
  Textarea,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useColorModeValue,
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import { get, isEmpty } from 'lodash';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFetch, useRequest } from '../utils/api';
import { VerticalContext } from '../commons/contexts';

const formatError = error => {
  if (error.message) {
    return error.message;
  }
  if (error instanceof Array) {
    return error.concat('');
  }
};

function Feedback() {
  const { vertical } = useContext(VerticalContext);
  const [updateError, setUpdateError] = useState(null);
  const [submitted, setSubmitted] = useState(null);
  const navigate = useNavigate();
  const colorModeValue = useColorModeValue('white', 'gray.800');
  const { data: branches = [] } = useFetch('/branch-options', 'get', {
    vertical,
  });
  const { request: createFeedback } = useRequest('create-feedback', 'post');
  const {
    reset,
    handleSubmit,
    register,
    control,
    formState: { errors: formErrors, isSubmitting },
  } = useForm();

  const onSubmit = async values => {
    setUpdateError(null);
    const { data, error } = await createFeedback(values);
    if (error) {
      setUpdateError(error);
      return;
    }
    reset();
    setSubmitted(data);
  };

  const errors = {
    ...formErrors,
    ...get(updateError, 'response.data.errors', {}),
  };

  return (
    <Box>
      <Heading as="h2" size="lg" marginBottom={`1rem`}>
        Submit Feedback
      </Heading>
      {!isEmpty(submitted) ? (
        <Box bg={colorModeValue} rounded={'lg'} boxShadow={'lg'} p={6}>
          Thank you for reaching out and providing us with valuable feedback.
          <Text color="teal.500">
            <RouterLink to={'/'}>Back to home</RouterLink>
          </Text>
        </Box>
      ) : (
        <Box bg={colorModeValue} rounded={'lg'} boxShadow={'lg'} px={6} py={10}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {updateError && (
              <Text color="tomato" align={'center'} className="tw-mb-2">
                {updateError?.message}
              </Text>
            )}
            <VStack spacing={5}>
              <FormControl isInvalid={errors.organization_id}>
                <FormLabel>Branch</FormLabel>
                <Select {...register('organization_id')}>
                  <option></option>
                  {branches.map(branch => (
                    <option value={branch.id} key={branch.id}>
                      {branch.shortname}
                    </option>
                  ))}
                </Select>
                {!!errors.organization_id && (
                  <FormErrorMessage>
                    {formatError(errors.organization_id)}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isRequired isInvalid={errors.rating}>
                <FormLabel>Rating (1: Poor - 5: Excellent)</FormLabel>
                <Controller
                  name="rating"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      <HStack spacing={5}>
                        <Radio value="1">1</Radio>
                        <Radio value="2">2</Radio>
                        <Radio value="3">3</Radio>
                        <Radio value="4">4</Radio>
                        <Radio value="5">5</Radio>
                      </HStack>
                    </RadioGroup>
                  )}
                ></Controller>
                {!!errors.rating && (
                  <FormErrorMessage>
                    {formatError(errors.rating)}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isRequired isInvalid={errors.content}>
                <FormLabel>Feedback</FormLabel>
                <Textarea
                  rows={12}
                  {...register('content', { required: 'This is required' })}
                />
                {!!errors.content && (
                  <FormErrorMessage>
                    {formatError(errors.content)}
                  </FormErrorMessage>
                )}
              </FormControl>

              <Box textAlign={'right'} width="full">
                <Button
                  type="button"
                  colorScheme={'blue'}
                  marginRight={4}
                  variant="outline"
                  onClick={() => navigate('/home')}
                >
                  Back
                </Button>

                <Button
                  isLoading={isSubmitting}
                  type="submit"
                  colorScheme={'blue'}
                >
                  Submit
                </Button>
              </Box>
            </VStack>
          </form>
        </Box>
      )}
    </Box>
  );
}

export default Feedback;
