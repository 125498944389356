import React, { useState } from 'react';
import {
  Stack,
  Text,
  Button,
  Box,
  VStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  useColorModeValue,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import { useRequest } from '../utils/api';

const formatError = error => {
  if (error.message) {
    return error.message;
  }
  if (error instanceof Array) {
    return error.concat('');
  }
};

function DeleteProfile() {
  const [updateError, setUpdateError] = useState(null);
  const navigate = useNavigate();
  const colorModeValue = useColorModeValue('white', 'gray.700');
  const { request: deleteProfile } = useRequest('delete-profile', 'post');
  const {
    handleSubmit,
    register,
    formState: { errors: formErrors, isSubmitting },
  } = useForm();

  const onSubmit = async values => {
    setUpdateError(null);
    const { error } = await deleteProfile(values);
    if (error) {
      setUpdateError(error);
      return;
    }
    navigate('/home');
  };

  const errors = {
    ...formErrors,
    ...get(updateError, 'response.data.errors', {}),
  };

  return (
    <div>
      <div className="tw-flex tw-justify-end">
        <ColorModeSwitcher></ColorModeSwitcher>
      </div>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <img
            src="./logo_full.png"
            alt="SS Wellness"
            style={{ height: '120px' }}
          />
          <Text fontSize={'lg'} color={'gray.600'}>
            Delete Account
          </Text>
        </Stack>
        <Box rounded={'lg'} bg={colorModeValue} boxShadow={'lg'} p={8}>
          <Text marginBottom={5}>
            You are about to <strong>delete</strong> your SS Wellness account,
            please note that once your account is deleted, it{' '}
            <strong>cannot be restored</strong>, and all your information
            including your{' '}
            <strong>
              reward points, prepaid balance, sales and receipt history
            </strong>{' '}
            will be <strong>permanently lost</strong>. <br /> <br />
            Therefore, we strongly advise you to carefully consider this
            decision before proceeding.
          </Text>
          <form onSubmit={handleSubmit(onSubmit)}>
            {updateError && (
              <Text color="tomato" align={'center'} className="tw-mb-2">
                {updateError?.message}
              </Text>
            )}
            <VStack spacing={5}>
              <FormControl isRequired isInvalid={errors.password}>
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  {...register('password', { required: 'This is required' })}
                />
                {!!errors.password && (
                  <FormErrorMessage>
                    {formatError(errors.password)}
                  </FormErrorMessage>
                )}
              </FormControl>
              <Box textAlign={'right'} width="full">
                <Button
                  type="button"
                  colorScheme={'blue'}
                  marginRight={4}
                  variant="outline"
                  onClick={() => navigate('/home')}
                >
                  Back
                </Button>
                <Button
                  isLoading={isSubmitting}
                  type="submit"
                  colorScheme={'red'}
                >
                  Confirm Deletion
                </Button>
              </Box>
            </VStack>
          </form>
        </Box>
      </Stack>
    </div>
  );
}

export default DeleteProfile;
