import React, { useState } from 'react';
import { useIsAuthenticated } from 'react-auth-kit';
import { Navigate } from 'react-router-dom';
import { Stack, Text } from '@chakra-ui/react';
import LoginPassword from './LoginPassword';
import LoginTwoFactor from './LoginTwoFactor';

function Login() {
  const [twoFactorMode, setTwoFactorMode] = useState(false);
  const [loginWith, setLoginWith] = useState('email');
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated()) {
    return <Navigate to={'/home'} replace />;
  }

  return (
    <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
      <Stack align={'center'}>
        <img
          src="./logo_full.png"
          alt="SS Wellness"
          style={{ height: '120px' }}
        />
        <Text fontSize={'lg'} color={'gray.600'}>
          Customer Login
        </Text>
      </Stack>
      {twoFactorMode ? (
        <LoginTwoFactor
          defaultLoginWith={loginWith}
          onCancel={currLoginWith => {
            setTwoFactorMode(false);
            setLoginWith(currLoginWith);
          }}
        />
      ) : (
        <LoginPassword
          defaultLoginWith={loginWith}
          onChangeMode={currLoginWith => {
            setTwoFactorMode(true);
            setLoginWith(currLoginWith);
          }}
        />
      )}
    </Stack>
  );
}

export default Login;
