import React, { useContext } from 'react';
import {
  Box,
  Stack,
  Wrap,
  WrapItem,
  Heading,
  useColorModeValue,
  Divider,
  Text,
  Button,
  Link,
} from '@chakra-ui/react';
import { BiReceipt } from 'react-icons/bi';
import { useAuthHeader } from 'react-auth-kit';
import { useInfiniteFetch } from '../utils/api';
import { formatNumber, formatCurrency, formatDate } from '../utils';
import { VerticalContext } from '../commons/contexts';

function Transactions() {
  const { vertical } = useContext(VerticalContext);
  const authHeader = useAuthHeader();
  const colorModeValue = useColorModeValue('white', 'gray.800');
  const { isFetching, data, hasNextPage, fetchNextPage } = useInfiniteFetch(
    '../resource/app-sale-history',
    'get',
    {
      vertical,
      rowsPerPage: 30,
    }
  );

  const token = authHeader().replace('bearer ', '');

  return (
    <div>
      <Heading as="h2" size="lg" marginBottom={`1rem`}>
        Transactions
      </Heading>
      <Box bg={colorModeValue} rounded={'lg'} boxShadow={'lg'}>
        {data && (
          <React.Fragment>
            {data.pages.map(({ processedData = [] }) => {
              return processedData.map(trx => {
                return (
                  <React.Fragment key={trx.id}>
                    <Stack
                      p={3}
                      py={3}
                      justifyContent={{
                        base: 'space-around',
                      }}
                      direction={{
                        base: 'row',
                      }}
                      alignItems={{ md: 'center' }}
                    >
                      <Heading
                        size={'sm'}
                        minWidth={55}
                        textAlign="center"
                        color="gray.500"
                      >
                        {formatDate(trx.date)}
                      </Heading>
                      <Box w="100%">
                        <Box
                          fontSize={{ base: 'md', md: 'lg' }}
                          fontWeight="medium"
                          whiteSpace={'pre-wrap'}
                        >
                          {formatNumber(trx.quantity) + ' × '}
                          {trx.description}
                        </Box>
                        <Wrap fontSize={'sm'} color="gray.500" spacing={'0'}>
                          <WrapItem>
                            <Text marginRight={'.25rem'}>{trx.branch}</Text>
                          </WrapItem>
                          {!!trx.agent_name && (
                            <WrapItem>
                              <Text>({trx.agent_name})</Text>
                            </WrapItem>
                          )}
                          <WrapItem>
                            <Link
                              href={`${process.env.REACT_APP_API_URL}receipts/${trx.code}/download?token=${token}`}
                              marginLeft={'.5rem'}
                              display={'flex'}
                              alignItems={'center'}
                              target="_blank"
                            >
                              <BiReceipt className="tw-mr-1"></BiReceipt>
                              {trx.code}
                            </Link>
                          </WrapItem>
                        </Wrap>
                      </Box>
                      <Heading
                        pl={3}
                        size={'sm'}
                        className="tw-whitespace-nowrap"
                        textAlign="right"
                      >
                        {formatCurrency(trx.total)}
                      </Heading>
                    </Stack>
                    <Divider></Divider>
                  </React.Fragment>
                );
              });
            })}
          </React.Fragment>
        )}
        {(hasNextPage || isFetching) && (
          <Button
            display={'block'}
            width={'100%'}
            onClick={() => fetchNextPage()}
            disabled={isFetching}
            isLoading={isFetching}
          >
            Load More
          </Button>
        )}
      </Box>
    </div>
  );
}

export default Transactions;
