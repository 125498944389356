import axios from 'axios';
import { get, set, sumBy } from 'lodash';
import { useAuthHeader, useSignOut } from 'react-auth-kit';
import { useQuery, useMutation, useInfiniteQuery } from '@tanstack/react-query';

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 5000,
  withCredentials: false,
});

export const api = async (
  url,
  method = 'get',
  params = {},
  config = {},
  unauthenticatedCallback = () => {}
) => {
  try {
    const { data: respData } = await http.request({
      url,
      method,
      [method.toLowerCase() === 'get' ? 'params' : 'data']: params,
      ...config,
    });
    return respData;
  } catch (error) {
    error.code = get(error, 'response.status', error.code);
    error.message = get(
      error,
      'response.data.message',
      get(error, 'response.data.error', error.message)
    );
    if (error.code === 401) unauthenticatedCallback();
    throw error;
  }
};

export function useFetch(url, method, params = {}, config = {}) {
  const authHeader = useAuthHeader()();
  const signOut = useSignOut();
  const queryKey = [url, method, params, authHeader];
  const {
    isFetching: loading,
    data,
    error,
  } = useQuery({
    queryKey,
    queryFn: () => {
      if (authHeader) set(config, 'headers.Authorization', authHeader);
      return api(url, method, params, config, () => signOut());
    },
  });

  return { loading, data, error };
}

export function useInfiniteFetch(url, method, params = {}, config = {}) {
  const authHeader = useAuthHeader()();
  const signOut = useSignOut();
  const queryKey = [url, method, params, authHeader];
  return useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam: page }) => {
      if (authHeader) set(config, 'headers.Authorization', authHeader);
      return api(url, method, { ...params, page }, config, () => signOut());
    },
    getNextPageParam: (lastPage, pages) => {
      const processedRows = sumBy(pages, page => page.processedData.length);
      if (lastPage.totalRows > processedRows) {
        return pages.length + 1;
      }
      return null;
    },
  });
}

export function useRequest(url, method, config = {}) {
  const authHeader = useAuthHeader()();
  const signOut = useSignOut();
  const {
    isLoading: loading,
    mutate,
    error,
  } = useMutation({
    mutationFn: params => {
      if (authHeader) set(config, 'headers.Authorization', authHeader);
      return api(url, method, params, config, () => signOut());
    },
  });
  return {
    loading,
    error,
    request: (params = {}) =>
      new Promise(resolve => {
        mutate(params, {
          onSuccess: data => resolve({ data }),
          onError: error =>
            resolve({ data: get(error, 'response.data'), error }),
        });
      }),
  };
}
