import React, { useContext } from 'react';
import { Button, HStack } from '@chakra-ui/react';
import { VerticalContext } from './contexts';

export default function VerticalSelector() {
  const {
    vertical: selectedVertical,
    verticals,
    setVertical,
  } = useContext(VerticalContext);

  return (
    <HStack>
      {(verticals || []).map(vertical => (
        <Button
          key={vertical}
          colorScheme="blue"
          variant={selectedVertical === vertical ? 'solid' : 'outline'}
          onClick={() => setVertical(vertical)}
        >
          {vertical}
        </Button>
      ))}
    </HStack>
  );
}
