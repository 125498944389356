import React from 'react';
import { Outlet } from 'react-router-dom';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import './index.css';

function GuestLayout() {
  return (
    <div>
      <div className="tw-flex tw-justify-end">
        <ColorModeSwitcher></ColorModeSwitcher>
      </div>
      <Outlet />
    </div>
  );
}

export default GuestLayout;
