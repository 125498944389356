import React, { useContext, useMemo } from 'react';
import {
  Box,
  Center,
  Stack,
  Heading,
  Text,
  Button,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  HStack,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useAuthUser } from 'react-auth-kit';
import { BsGift } from 'react-icons/bs';
import {
  BiDoorOpen,
  BiWallet,
  BiShoppingBag,
  BiPencil,
  BiEnvelope,
  BiCrown,
  BiAlarm,
} from 'react-icons/bi';
import { useFetch } from '../utils/api';
import { VerticalContext } from '../commons/contexts';
import {
  formatRelative,
  formatNumber,
  formatCurrency,
  formatDate,
} from '../utils';
import News from './News';
import VerticalSelector from '../commons/VerticalSelector';

function StatsCard(props) {
  const { title, stat, icon, ...statProps } = props;
  return (
    <Stat
      px={{ base: 2, md: 4 }}
      py={'5'}
      shadow={'xl'}
      borderColor={useColorModeValue('gray.800', 'gray.500')}
      bg={useColorModeValue('white', 'gray.800')}
      rounded={'lg'}
      {...(statProps || {})}
    >
      <Flex justifyContent={'space-between'}>
        <Box pl={{ base: 2, md: 4 }}>
          <StatLabel fontWeight={'medium'}>{title}</StatLabel>
          <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
            {stat}
          </StatNumber>
        </Box>
        <Box
          my={'auto'}
          color={useColorModeValue('gray.800', 'gray.200')}
          alignContent={'center'}
        >
          {icon}
        </Box>
      </Flex>
    </Stat>
  );
}

function Home() {
  const auth = useAuthUser();
  const { vertical } = useContext(VerticalContext);
  const { data: profileData = {} } = useFetch('/me', 'get');
  const { data } = useFetch('/stats', 'get');

  const info = data?.[vertical];

  const user = useMemo(() => {
    return { ...auth(), ...profileData };
  }, [auth, profileData]);

  return (
    <div>
      <Center>
        <Box
          w={'full'}
          bg={useColorModeValue('white', 'gray.800')}
          boxShadow={'2xl'}
          rounded={'md'}
          overflow={'hidden'}
          marginBottom={'1.5rem'}
        >
          <Box p={6} position={'relative'}>
            <Box position="absolute" right={3} top={3}>
              <Link to={'/profile'}>
                <Button variant="outline" rounded={'full'} p={2}>
                  <BiPencil />
                </Button>
              </Link>
            </Box>
            <Stack spacing={0} align={{ base: 'center', md: 'start' }} mb={5}>
              <Heading fontSize={'2xl'} fontWeight={500} marginBottom={`.5rem`}>
                {user.name}
              </Heading>

              <Text color={'gray.500'}>{user.email}</Text>
              <Text color={'gray.500'}>{user.contact}</Text>
              <HStack spacing={'2'} paddingTop={'.5rem'}>
                {user.subscribe_transactional && (
                  <Text
                    fontSize={'xs'}
                    fontWeight="medium"
                    backgroundColor={'#42a5f5'}
                    color={'white'}
                    px={2}
                    py={0.5}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <BiEnvelope className="tw-mr-1" /> Transactional
                  </Text>
                )}
                {user.subscribe_promotional && (
                  <Text
                    fontSize={'xs'}
                    fontWeight="medium"
                    backgroundColor={'#42a5f5'}
                    color={'white'}
                    px={2}
                    py={0.5}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <BiEnvelope className="tw-mr-1" /> Promotional
                  </Text>
                )}
              </HStack>
              <Box paddingTop={'.5rem'} textAlign="left" width="full">
                <Text>Location: {user.location || '-'}</Text>
                <Text>Gender: {user.gender_label || '-'}</Text>
                <Text>
                  Date of Birth:{' '}
                  {user.date_of_birth ? formatDate(user.date_of_birth) : '-'}
                </Text>
                <Text>Joined Since: {formatRelative(user.created_at)}</Text>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Center>
      <Flex
        display={{ base: 'flex', md: 'none' }}
        alignItems="center"
        marginBottom="1.5rem"
      >
        <VerticalSelector />
      </Flex>
      {info && (
        <Box>
          {info.tier_label && (
            <StatsCard
              title={'Tier'}
              stat={info.tier_label}
              icon={<BiCrown size={'3em'} />}
              className={`shine-box tier-${info.tier_label}`}
              marginBottom={'1.2rem'}
            />
          )}
          <SimpleGrid columns={{ base: 1, md: 4 }} spacing={{ base: 5, lg: 8 }}>
            <StatsCard
              title={'Last Visit'}
              stat={formatRelative(info.last_visit_date)}
              icon={<BiDoorOpen size={'3em'} />}
            />
            <StatsCard
              title={'Reward Points'}
              stat={
                <Link to={'/points'}>{formatNumber(info.total_reward)}</Link>
              }
              icon={<BsGift size={'3em'} />}
            />
            <StatsCard
              title={
                vertical === 'BEAUTY'
                  ? 'Facial Credit Balance'
                  : 'Prepaid Balance'
              }
              stat={
                <Link to={'/prepaid'}>{formatCurrency(info.total_credit)}</Link>
              }
              icon={<BiWallet size={'3em'} />}
            />
            <StatsCard
              title={'Hours Balance'}
              stat={<Link to={'/hours'}>{formatNumber(info.total_hours)}</Link>}
              icon={<BiAlarm size={'3em'} />}
            />
            <StatsCard
              title={'Total Purchased'}
              stat={
                <Link to={'/transactions'}>
                  {formatNumber(info.total_sales)}
                </Link>
              }
              icon={<BiShoppingBag size={'3em'} />}
            />
          </SimpleGrid>
        </Box>
      )}
      <News />
    </div>
  );
}

export default Home;
