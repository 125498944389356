import React from 'react';
import { AuthProvider } from 'react-auth-kit';
import { ChakraProvider, theme } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Routes from './Routes';
import './index.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 5, // 5 seconds
      retry: false,
    },
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <AuthProvider authType={'localstorage'} authName="_auth">
        <QueryClientProvider client={queryClient}>
          <Routes />
        </QueryClientProvider>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
