import React, { useEffect, useState } from 'react';
import {
  Heading,
  Box,
  Text,
  Button,
  Input,
  Select,
  VStack,
  HStack,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  useColorModeValue,
  Checkbox,
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import { get, pick } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useFetch, useRequest } from '../utils/api';

const formatError = error => {
  if (error.message) {
    return error.message;
  }
  if (error instanceof Array) {
    return error.concat('');
  }
};

function Profile() {
  const [updateError, setUpdateError] = useState(null);
  const navigate = useNavigate();
  const colorModeValue = useColorModeValue('white', 'gray.800');
  const { data } = useFetch('/me', 'get');
  const { data: locations = [] } = useFetch('/location-options', 'get');
  const { request: updateProfile } = useRequest('update-profile', 'post');
  const {
    reset,
    handleSubmit,
    register,
    control,
    formState: { errors: formErrors, isSubmitting },
  } = useForm();

  useEffect(() => {
    if (data)
      reset(
        pick(data, [
          'name',
          'contact',
          'email',
          'location',
          'gender',
          'date_of_birth',
          'subscribe_promotional',
          'subscribe_transactional',
        ])
      );
  }, [data, reset]);

  const onSubmit = async values => {
    setUpdateError(null);
    const { error } = await updateProfile(values);
    if (error) {
      setUpdateError(error);
      return;
    }
    navigate('/home');
  };

  const errors = {
    ...formErrors,
    ...get(updateError, 'response.data.errors', {}),
  };

  return (
    <Box>
      <Heading as="h2" size="lg" marginBottom={`1rem`}>
        Edit Profile
      </Heading>
      <Box bg={colorModeValue} rounded={'lg'} boxShadow={'lg'} px={6} py={10}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {updateError && (
            <Text color="tomato" align={'center'} className="tw-mb-2">
              {updateError?.message}
            </Text>
          )}
          <VStack spacing={5}>
            <FormControl isRequired isInvalid={errors.name}>
              <FormLabel>Name</FormLabel>
              <Input {...register('name', { required: 'This is required' })} />
              {!!errors.name && (
                <FormErrorMessage>{formatError(errors.name)}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={errors.email}>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                {...register('email', {
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                })}
              />
              {!!errors.email && (
                <FormErrorMessage>{formatError(errors.email)}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={errors.contact}>
              <FormLabel>Mobile</FormLabel>
              <Input
                placeholder="01xxxxxxxx"
                {...register('contact', {
                  minLength: {
                    value: 4,
                    message:
                      'Invalid mobile number format, should be 01xxxxxxxx',
                  },
                })}
              />
              {!!errors.contact && (
                <FormErrorMessage>
                  {formatError(errors.contact)}
                </FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={errors.gender}>
              <FormLabel>Gender</FormLabel>
              <Controller
                name="gender"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <HStack>
                      <Radio value="M">Male</Radio>
                      <Radio value="F">Female</Radio>
                    </HStack>
                  </RadioGroup>
                )}
              ></Controller>
              {!!errors.gender && (
                <FormErrorMessage>
                  {formatError(errors.gender)}
                </FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={errors.date_of_birth}>
              <FormLabel>Date of Birth</FormLabel>
              <Input type="date" disabled {...register('date_of_birth')} />
              <FormHelperText>
                Please approach any of our branches for amendment if there's a
                mistake on your date of birth.
              </FormHelperText>
              {!!errors.date_of_birth && (
                <FormErrorMessage>
                  {formatError(errors.date_of_birth)}
                </FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={errors.location}>
              <FormLabel>Location</FormLabel>
              <Select {...register('location')}>
                {locations.map(location => (
                  <option value={location.code} key={location.code}>
                    {location.description}
                  </option>
                ))}
              </Select>
              {!!errors.location && (
                <FormErrorMessage>
                  {formatError(errors.location)}
                </FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={errors.subscribe_promotional}>
              <Controller
                name="subscribe_promotional"
                control={control}
                render={({ field }) => (
                  <Checkbox {...field} isChecked={field.value}>
                    Subscribe to promotional messages
                  </Checkbox>
                )}
              />
              {!!errors.subscribe_promotional && (
                <FormErrorMessage>
                  {formatError(errors.subscribe_promotional)}
                </FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={errors.subscribe_transactional}>
              <Controller
                name="subscribe_transactional"
                control={control}
                render={({ field }) => (
                  <Checkbox {...field} isChecked={field.value}>
                    Subscribe to transactional messages
                  </Checkbox>
                )}
              />
              {!!errors.subscribe_transactional && (
                <FormErrorMessage>
                  {formatError(errors.subscribe_transactional)}
                </FormErrorMessage>
              )}
            </FormControl>
            <Box textAlign={'right'} width="full" paddingTop={15}>
              <Button
                type="button"
                colorScheme={'red'}
                float="left"
                onClick={() => navigate('/delete-profile')}
              >
                Delete Account
              </Button>
              <Button
                type="button"
                colorScheme={'blue'}
                marginRight={4}
                variant="outline"
                onClick={() => navigate('/home')}
              >
                Back
              </Button>

              <Button
                isLoading={isSubmitting}
                type="submit"
                colorScheme={'blue'}
              >
                Update
              </Button>
            </Box>
          </VStack>
        </form>
      </Box>
    </Box>
  );
}

export default Profile;
