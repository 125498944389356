import { format, formatDistance } from 'date-fns';
import accounting from 'accounting';
import { sortBy, reverse } from 'lodash';

accounting.settings = {
  currency: {
    symbol: 'RM',
    format: '%s %v',
    decimal: '.',
    thousand: ',',
    precision: 2,
    grouping: 3,
  },
  number: {
    precision: 4,
    grouping: 3,
    thousand: ',',
    decimal: '.',
    trailing: false,
  },
};

const DATETIME_FORMAT = 'd MMM yyyy HH:mm:ss'; // 17 Nov 2020 14:33:28
const DATE_FORMAT = 'd MMM yyyy'; // 17 Nov 2020

export const formatDateTime = (raw, fmt = DATETIME_FORMAT) =>
  raw ? format(new Date(raw), fmt) : '-';

export const formatDate = (raw, fmt = DATE_FORMAT) =>
  raw ? format(new Date(raw), fmt) : '-';

export const formatRelative = raw =>
  raw
    ? formatDistance(new Date(raw), new Date(), {
        addSuffix: true,
      })
    : '-';

export const formatNumber = number => {
  let formatted = accounting.formatNumber(number);
  if (formatted.indexOf('.') > 0) formatted = formatted.replace(/\.?0+$/, '');
  return formatted;
};

export const formatCurrency = number => {
  return accounting.formatMoney(number);
};

export const getUserVerticals = user => {
  return reverse(
    sortBy(user?.infos || [], info => {
      return info.last_transact_date || '0000-00-00 00:00:00';
    })
  ).map(info => {
    return info.vertical_id;
  });
};
