import React, { useContext, useMemo } from 'react';
import {
  Box,
  Stack,
  Wrap,
  WrapItem,
  Heading,
  useColorModeValue,
  Divider,
  Text,
  Button,
} from '@chakra-ui/react';
import { get } from 'lodash';
import { useInfiniteFetch } from '../utils/api';
import { formatDate, formatNumber } from '../utils';
import { VerticalContext } from '../commons/contexts';

function HoursStatement() {
  const { vertical } = useContext(VerticalContext);
  const colorModeValue = useColorModeValue('white', 'gray.800');
  const { isFetching, data, hasNextPage, fetchNextPage } = useInfiniteFetch(
    '../resource/app-hours-statement',
    'get',
    {
      vertical,
      rowsPerPage: 30,
    }
  );

  const total = useMemo(() => {
    return get(data, ['pages', 0, 'aggregated', 'amount']) || 0;
  }, [data]);

  return (
    <div>
      <Heading as="h2" size="lg" marginBottom={`1rem`}>
        Hours Balance ({formatNumber(total)})
      </Heading>
      <Box bg={colorModeValue} rounded={'lg'} boxShadow={'lg'}>
        {data && (
          <React.Fragment>
            {data.pages.map(({ processedData = [] }) => {
              return processedData.map(trx => {
                return (
                  <React.Fragment key={trx.id}>
                    <Stack
                      p={3}
                      py={3}
                      justifyContent={{
                        base: 'space-around',
                      }}
                      direction={{
                        base: 'row',
                      }}
                      alignItems={{ md: 'center' }}
                    >
                      <Heading
                        size={'sm'}
                        minWidth={55}
                        textAlign="center"
                        color="gray.500"
                      >
                        {formatDate(trx.date)}
                      </Heading>
                      <Box w="100%">
                        <Box
                          fontSize={{ base: 'md', md: 'lg' }}
                          fontWeight="medium"
                          whiteSpace={'pre-wrap'}
                        >
                          {trx.sale}
                        </Box>
                        <Wrap fontSize={'sm'} color="gray.500" spacing={'0'}>
                          <WrapItem>
                            <Text marginRight={'.25rem'}>{trx.branch}</Text>
                          </WrapItem>
                        </Wrap>
                      </Box>
                      <Heading
                        pl={3}
                        size={'sm'}
                        className="tw-whitespace-nowrap"
                        textAlign="right"
                        color={trx.amount >= 0 ? 'lightgreen' : 'tomato'}
                      >
                        {formatNumber(trx.amount)}
                      </Heading>
                    </Stack>
                    <Divider></Divider>
                  </React.Fragment>
                );
              });
            })}
          </React.Fragment>
        )}
        {(hasNextPage || isFetching) && (
          <Button
            display={'block'}
            width={'100%'}
            onClick={() => fetchNextPage()}
            disabled={isFetching}
            isLoading={isFetching}
          >
            Load More
          </Button>
        )}
      </Box>
    </div>
  );
}

export default HoursStatement;
