import React, { useContext } from 'react';
import {
  Box,
  Stack,
  Heading,
  useColorModeValue,
  Divider,
  Text,
  Button,
} from '@chakra-ui/react';
import { useInfiniteFetch } from '../utils/api';
import { formatCurrency } from '../utils';
import { VerticalContext } from '../commons/contexts';

function Vouchers() {
  const { vertical } = useContext(VerticalContext);
  const colorModeValue = useColorModeValue('white', 'gray.800');
  const { isFetching, data, hasNextPage, fetchNextPage } = useInfiniteFetch(
    '../resource/app-voucher',
    'get',
    {
      vertical,
      rowsPerPage: 30,
    }
  );

  return (
    <div>
      <Heading as="h2" size="lg" marginBottom={`1rem`}>
        Vouchers
      </Heading>
      <Box bg={colorModeValue} rounded={'lg'} boxShadow={'lg'}>
        {data && (
          <React.Fragment>
            {data.pages.map(({ processedData = [] }) => {
              return processedData.map(v => {
                return (
                  <React.Fragment key={v.id}>
                    <Stack
                      p={3}
                      py={3}
                      justifyContent={'space-between'}
                      direction={'row'}
                      alignItems={'center'}
                    >
                      <Box>
                        <Text fontWeight={'semibold'}>{v.code}</Text>
                      </Box>
                      <Box
                        pl={3}
                        fontSize={'sm'}
                        width={'full'}
                        className="tw-whitespace-nowrap"
                        textAlign="right"
                      >
                        {formatCurrency(v.balance)}
                      </Box>
                    </Stack>
                    <Divider></Divider>
                  </React.Fragment>
                );
              });
            })}
          </React.Fragment>
        )}
        {(hasNextPage || isFetching) && (
          <Button
            display={'block'}
            width={'100%'}
            onClick={() => fetchNextPage()}
            disabled={isFetching}
            isLoading={isFetching}
          >
            Load More
          </Button>
        )}
      </Box>
    </div>
  );
}

export default Vouchers;
