import React, { useState } from 'react';
import { useSignIn } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Stack,
  Button,
  Text,
  Link,
  useColorModeValue,
} from '@chakra-ui/react';
import { useRequest } from './utils/api';

const COOLDOWN_SECONDS = 60;
let counterInterval;

function LoginTwoFactor({ defaultLoginWith, onCancel }) {
  const [loginWith, setLoginWith] = useState(defaultLoginWith);
  const [loginError, setLoginError] = useState(null);
  const [codeRequested, setCodeRequested] = useState(false);
  const [resendCooldown, setResendCooldown] = useState(0);
  const signIn = useSignIn();
  const navigate = useNavigate();
  const { request: loginRequest } = useRequest('login', 'post');
  const { request: codeRequest } = useRequest('request-mfc', 'post');
  const colorModeValue = useColorModeValue('white', 'gray.700');

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    unregister,
    reset,
  } = useForm();

  const resetForm = () => {
    setCodeRequested(false);
    setLoginError(null);
    unregister('code');
  };

  const onCodeRequest = async values => {
    clearInterval(counterInterval);
    setLoginError(null);
    const { data, error } = await codeRequest({
      ...values,
      method: loginWith === 'mobile' ? 'contact' : 'email',
    });
    if (error) {
      setLoginError(error.message);
      return;
    }
    setCodeRequested(data);
    setResendCooldown(COOLDOWN_SECONDS);
    counterInterval = setInterval(() => {
      setResendCooldown(v => v - 1);
    }, 1000);
  };

  const onLogin = async values => {
    setLoginError(null);
    const { data, error } = await loginRequest({
      ...values,
      method: loginWith === 'mobile' ? 'contact' : 'email',
    });
    if (error) {
      setLoginError(error.message);
      return;
    }
    if (
      signIn({
        token: data.access_token,
        expiresIn: data.expires_in,
        tokenType: data.token_type,
        authState: data.data,
      })
    ) {
      navigate('/change-password', { state: { mustChange: true } });
    } else {
      setLoginError('Error logging in.');
    }
  };

  return (
    <Box rounded={'lg'} bg={colorModeValue} boxShadow={'lg'} p={8}>
      <form onSubmit={handleSubmit(!codeRequested ? onCodeRequest : onLogin)}>
        {loginError && (
          <Text color="tomato" align={'center'} className="tw-mb-2">
            {loginError}
          </Text>
        )}
        <Stack spacing={4}>
          <FormControl id="username" isInvalid={errors.username}>
            <FormLabel htmlFor="username">
              {!codeRequested && (
                <Link
                  float="right"
                  fontSize={'xs'}
                  marginTop={'.25rem'}
                  textColor="blue.400"
                  onClick={() => {
                    setLoginWith(loginWith === 'email' ? 'mobile' : 'email');
                    reset();
                  }}
                >
                  {loginWith === 'email' ? 'Use Mobile' : 'Use Email'}
                </Link>
              )}
              {loginWith === 'email' ? 'Email' : 'Mobile Number'}
            </FormLabel>
            {loginWith === 'email' ? (
              <Input
                type="email"
                id="username"
                readOnly={!!codeRequested || isSubmitting}
                {...register('username', {
                  required: 'This is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                })}
              />
            ) : (
              <Input
                type="text"
                id="username"
                readOnly={!!codeRequested || isSubmitting}
                placeholder="01xxxxxxxx"
                {...register('username', {
                  required: 'This is required',
                  minLength: {
                    value: 4,
                    message: 'Minimum length should be 9',
                  },
                })}
              />
            )}
            {!!errors.username && (
              <FormErrorMessage>{errors.username.message}</FormErrorMessage>
            )}
          </FormControl>
          {codeRequested && (
            <FormControl id="code" isInvalid={errors.code}>
              <FormLabel htmlFor="code">
                {resendCooldown > 0 ? (
                  <Text
                    float="right"
                    fontSize={'xs'}
                    marginTop={'.25rem'}
                    textColor="blue.500"
                  >
                    Resend in {resendCooldown} seconds
                  </Text>
                ) : (
                  <Link
                    float="right"
                    fontSize={'xs'}
                    marginTop={'.25rem'}
                    textColor="blue.400"
                    onClick={() => {
                      resetForm();
                      handleSubmit(onCodeRequest)();
                    }}
                  >
                    Resend
                  </Link>
                )}
                Two Factor Code
              </FormLabel>
              <Input
                type="text"
                id="code"
                readOnly={isSubmitting}
                {...register('code', {
                  required: 'This is required',
                  pattern: {
                    value: /^[0-9]{6}$/i,
                    message: 'Invalid format',
                  },
                })}
              />
              {!!errors.code && (
                <FormErrorMessage>{errors.code.message}</FormErrorMessage>
              )}
            </FormControl>
          )}
          <Stack spacing={4}>
            <Button
              isLoading={isSubmitting}
              type="submit"
              bg={'blue.400'}
              color={'white'}
              _hover={{ bg: 'blue.500' }}
            >
              {!codeRequested
                ? 'Request Two Factor Code'
                : 'Confirm Two Factor Code'}
            </Button>

            <Button
              isLoading={isSubmitting}
              type="button"
              onClick={() => onCancel(loginWith)}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </form>
    </Box>
  );
}

export default LoginTwoFactor;
